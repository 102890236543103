@import "main";
.loginBox{
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-image: url("../img/bg1.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  transition: all 0.5s ease 0s;
  canvas{
    z-index: -1;
  }
}
.formBox{
  position: relative;
  width: 240px;
  height: 300px;
  padding: 100px 40px 40px 40px;
  box-sizing: content-box;
  h3{
    text-align: center;
    letter-spacing: 2px;
    color: white;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .box{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 90px 40px 40px 40px;
    backface-visibility: hidden;
    background: linear-gradient(230deg, rgba(53, 57, 74, 0) 0%, rgb(0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(53, 57, 74, 0)', endColorstr='rgb(0, 0, 0)',GradientType=1 );
    box-shadow: -15px 15px 15px rgba(0,0,0,.4);
    transition: all 1s;
  }
}