// 颜色
$black:#000000;
$white:#FFFFFF;
$hs:#434343; // 加载条背景颜色
$btnSucess:#1890FF; // 按钮成功颜色
$btnRest:#67C23A;
// 背景图片地址和大小
@mixin bis($url) {
  background-image: url($url);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}
@mixin successBtnSty {
  cursor: pointer;
  padding: 10px 20px;
  color: $white;
  font-size: 14px;
  background: $btnRest;
  border: none;
  border-radius: 5px;
}
@mixin borderRadius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}
//定位全屏
@mixin allcover{
  position:absolute;
  top:0;
  right:0;
}

//定位上下左右居中
@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

//定位上下居中
@mixin ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

//定位左右居中
@mixin cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

//宽高
@mixin wh($width, $height){
  width: $width;
  height: $height;
}

//字体大小、行高、字体
@mixin font($size, $line-height, $family: 'Microsoft YaHei') {
  font: #{$size}/#{$line-height} $family;
}

//字体大小，颜色
@mixin sc($size, $color){
  font-size: $size;
  color: $color;
}

//flex 布局和 子元素 对其方式
@mixin fj($type: space-between){
  display: flex;
  justify-content: $type;
}

// 右浮动
@mixin fR() {
  float: right;
}
 .iconSty{
  font-size: 20px;
  cursor: pointer;
  padding: 0 10px;
}
.tr{
  text-align: right;
}
.tl{
  text-align: left;
}
.uploadBox{
  position: relative;

}
// 左浮动
@mixin fL() {
  float: left;
}