.play-show-box{
  padding: 0 0 10px 0;
  width: 80%;
  .video-list-p{
    float: right;
    margin: 5px 0;
    color: #1C7AFF;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
  }
  ul {
    overflow: hidden;
    display: flex;
    width: 100%;
    margin-left: 10px;
    justify-content: space-around;
    padding: 0 0 15px 0;
    border-bottom: 2px solid rgba(1, 1, 1, 0.4);
    li {
      h3{
        font-size: 20px;
        color: rgba(1, 1, 1, 0.6);
        font-weight: bold;
        margin: 15px 0;
      }
      span{
        font-size: 16px;
        color: rgba(1, 1, 1, 0.4);
      }
    }
  }
}
.hot-title-box{
  width: 80%;
  h3{
    text-align: center;
    font-size: 20px;
    font-weight: bold;

    margin-bottom: 30px;
  }
  ul {
    li{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;
      h4{
        font-weight: bold;
        width: 16%;
        color: rgba(1,1,1,0.5);
      }
      img {
        width: 189px;
        height: 105px;
        margin: 0 20px
      }
      .line{
        position: relative;
        span {
          position: absolute;
          bottom: -24px;
          font-size: 12px;
          font-weight: bold;
          color: rgba(1,1,1,0.5);
        }
      }
    }
  }
}

.FF7272 {
  background:rgba(255,114,114,1)
}
.FFB164 {
  background: #FFB164;
}
.FFF1C1 {
  background: #FFF1C1;
}