@import "./main";
@mixin commR() {
  margin-left: 10px;
}
.user-box {
  .nav {
    @include commR;
    overflow: hidden;
    .input, .select {
      float: left;
    }
    .select {
      margin-left: 80px;
    }
  }
}

.nav-btn {
  width: 80%;
  @include commR;
  border-bottom: 2px solid rgba(1,1,1,0.4);
  margin-top: 30px;
  padding-bottom: 20px;
  overflow: hidden;
  .btn-groub {
    float: right;
    button {
      @include successBtnSty;
      &:first-child {
        margin-right: 30px;
        background: $btnSucess;
      }
    }
  }
}
.create-box{
  margin: 10px 0;
  @include commR;
  >button{
    @include successBtnSty;
    a {
      color: white;
    }
  }
}
.table-box{
  @include commR;
  margin-right: 10px;
}
.span {
  padding: 0 5px;
}
.iconSty{
  font-size: 20px;
  cursor: pointer;
  padding: 0 10px;
}