@import "main";
@mixin commItemSty {
  width: 120px;
  height: 120px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-swiper-form {
   display: flex;
   flex-direction: column;
   margin-left: 400px;
  .form-item {
    //overflow: hidden;
    display: flex;
    align-items: center;
    justify-items: center;
    font-size: 12px;
    .form-title {
      font-size: 14px;
      vertical-align: middle;
      margin-right: 20px;
      em {
        font-size: 10px;
        color: red;
      }
    }
    .form-input {
      @include commItemSty;
      height: 120px;
      overflow: hidden;
      //background: #434343;
      border: dashed 1px rgba(1,1,1,0.5);
      .file{
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        cursor: pointer;
        z-index: 9999;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
      img{
        //width: 98%;

        height: 98%;
        position: absolute;
        z-index: 99;
        right: 0;
        bottom: 0;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
      }
    }
    .form-select {
      @include commItemSty;
      select{
        width: 105%;
        padding: 5px;
        border-radius: 4px;
      }
    }
    .form-put{
      @include commItemSty;
      input{
        width: 200px;
        border: solid 1px rgba(1,1,1,0.5);
        padding: 5px;
      }
    }

  }
}
.iconSize {
  font-size: 20px;
}
.edit-sty{
  border: solid 1px rgba(1,1,1,.4);
  max-width: 700px;
  max-height: 500px;
  overflow: auto;
}
.form-item-btn{
  margin-top: 10px;
  margin-left: 25px;
  button{
    //margin-left: 25px;
    cursor: pointer;
    padding: 10px 20px;
    color: #FFFFFF;
    font-size: 14px;
    background: #67C23A;
    border: none;
    border-radius: 5px;
    &:first-child{
      background: #1C7AFF;
      margin-right: 30px;
    }
  }
}