@import "main";
.headerBar-box {
  @include fR;
  color: $white;
  margin-right: 15px;
  cursor: pointer;
}
.header-list{
  @include fR;
  position: relative;
  top:-2px;
  margin-right: 15px;
  //color: white;
  cursor: pointer;
  >li {
    &:hover{
      color: #1C7AFF;
    }
    &:last-child{
      margin-top: 5px;
    }
  }
}