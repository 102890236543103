@import "main";

.video-form-box {
  font-size: 12px;
  padding:  0 300px;
}

.vTitle {
  font-size: 14px;
  em {
    color: red;
  }
}
@mixin inSty () {
  width: 260px;
  padding: 5px;
  border-radius: 4px;
}
.input-text {

  //border: solid 1px rgba(1, 1, 1, 0.4);
  @include inSty

}
.inSelect {
  @include inSty;
}
.rowR {
  margin: 30px 0;
  button{
    &:last-child{
      cursor: pointer;
      padding: 10px 20px;
      color: #FFFFFF;
      font-size: 14px;
      background: red;
      border: none;
      border-radius: 5px;
      border: none;
      margin-left: 30px;
  }
  }
}

.commPosstion {
  vertical-align: middle;
}

.file-upload-box {
  height: 200px;
  .file-upload-content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: dashed 1px rgba(1,1,1,0.5);
    //justify-items: center;
    //align-content: center;
    width: 200px;
    .file {
      cursor: pointer;
      position: absolute;
      z-index: 9999;
      width: 200px;
      height: 200px;
      opacity: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
    img {
      max-width: 98%;
      max-height: 98%;
      position: absolute;
      z-index: 999;
      right: 0;
      bottom: 0;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }

  }
}
.textf{
  height: 200px;
  width: 300px;
}