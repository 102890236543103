.video-title-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  font-size: 20px;
  color: rgba(1,1,1,0.6);
  .child {
    display: flex;
    justify-items: center;
    justify-content: center;
    //width: 40%;
    padding: 15px 0;
    h3{
      color: rgba(1,1,1,0.6);
    }
    .center-box {
      margin: 0 15px;
      em{
        margin: 0 10px;
      }
    }
  }
}