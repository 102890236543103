@import "main";
.comment-box::-webkit-scrollbar{
  display: none;
}
.comment-box {
  //border: solid 1px red;
  .comment-filter-item {
    margin-left: 2%;
    margin-bottom: 2%;
    h3 {
      margin-bottom: 20px;
    }
    .item {
      .item-list {
        margin-right: 3%;
      }
      .item-input {
        width: 200px;
      }
    }
  }
}

.nav-btn {
  width: 80%;
  border-bottom: 2px solid rgba(1, 1, 1, 0.4);
  margin-top: 30px;
  padding-bottom: 20px;
  overflow: hidden;
  .btn-groub {
    float: right;
    button {
      @include successBtnSty;
      &:first-child {
        margin-right: 30px;
        background: $btnSucess;
      }
    }
  }
}

.comment-li {
  ul {
    overflow: hidden;
    display: flex;
    width: 80%;
    margin-left: 1%;
    margin-top: 4%;
    padding-bottom: 4%;
    justify-content: space-between;
    border-bottom: 2px solid rgba(1, 1, 1, 0.4);
    li {
      text-align: center;
      //float: left;
      div{
        font-size: 20px;
        color: rgba(1,1,1,0.6);
        font-weight: bold;
        margin-bottom: 6px;
      }
      span {
        font-size: 16px;
        color: rgba(1,1,1,0.4);
      }
    }
  }
}
.boundary-box{
  padding: 20px;
  text-align: center;
  margin-left: 1%;
  width: 80%;
  border-bottom: 2px solid rgba(1, 1, 1, 0.4);
}
//td {
//  vertical-align: middle
//}
.idea-msg-input-box{
  display: inline-block;
  margin-left: 2%;
}