@import "main";

.video-list-box {
  width: 100%;
  .video-filter-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    .select-item {
      width: 12%;
      min-width: 160px;
      margin-left: 2%;
    }
  }
  .video-btn-group {
    margin-left: 2%;
    width: 80%;
    min-width: 700px;
    border-bottom: 2px solid rgba(1, 1, 1, 0.4);
    margin-top: 30px;
    padding-bottom: 20px;
    overflow: hidden;
    .btn-groub {
      float: right;
      button {
        @include successBtnSty;
        &:first-child {
          margin-right: 30px;
          background: $btnSucess;
        }
      }
    }
  }
  th,tr,td{
    vertical-align: middle;
  }
}

.video-table-list {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  overflow: auto;
  img{
    width: 60px;
    cursor: pointer;
  }
}

.type-btn {
  margin: 15px 0;
  button {
    margin-left: 10px;
    &:last-child {
      margin-left: 20px;
    }
  }
}
.setW{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 60px;
  cursor: pointer;

}
.classify_com {
  border-bottom: 1px solid #e8e8e8;
  padding: 2% 0;
  .classify_top  {
    padding: 20px 8%;
    .classify_top_inp {
      width: 12%;
      min-width: 160px;
      margin-left: 2%;
      margin-right: 10%;
    }
    button {
      margin-right: 2%;
      min-width: 95px;
    }
  }
  .classify_list {
    width: 70%;
    margin: 2% 0 2% 8.3%;
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
      text-align: center;
    }
    table {
      border: 1px solid #e8e8e8;
      margin-bottom: 20px;
    }
    .move_top {
      color:rgb(133,214,77);
    }
    .move_bom {
      color:rgb(254,192,45);
    }
  }
}
.close-box{
  .ant-modal-close{
    right: -11px;
    top: -12px;
  }
}
