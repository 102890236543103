@import "main";
.btn-group {
  margin-bottom: 10px;
  >button{
    @include successBtnSty;
    margin-left: 15px;
    margin-right: 15px;
    &.antd-btn{
      background:red;
    }
  }
}
.swiper-img {
  height: 80px;
}
.table-content {
  td,th {
    vertical-align: middle;
  }
}
.iconSty-swiper{
  //font-size: 20px;
  cursor: pointer;
  padding: 0 5px;
}
.content-f{
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}